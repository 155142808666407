/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:de7e969f-57f8-42f6-a1d3-16b27972eb35",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_dhOZ4GN0W",
    "aws_user_pools_web_client_id": "1278rcitk8u2u8ehn7cs93l865",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "pejuangweb",
            "endpoint": "https://cmih77z3nh.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        }
    ],
    "aws_content_delivery_bucket": "pejuang-web-hosting-prod",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d3mchytjd7mu79.cloudfront.net",
    "aws_user_files_s3_bucket": "pejuang-web-uploadprod-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-1",
    "dev": {
        "awscloudformation": {
            "AuthRoleName": "amplify-pejuang-web-dev-150722-authRole",
            "UnauthRoleArn": "arn:aws:iam::101896602497:role/amplify-pejuang-web-dev-150722-unauthRole",
            "AuthRoleArn": "arn:aws:iam::101896602497:role/amplify-pejuang-web-dev-150722-authRole",
            "Region": "ap-southeast-1",
            "DeploymentBucketName": "amplify-pejuang-web-dev-150722-deployment",
            "UnauthRoleName": "amplify-pejuang-web-dev-150722-unauthRole",
            "StackName": "amplify-pejuang-web-dev-150722",
            "StackId": "arn:aws:cloudformation:ap-southeast-1:101896602497:stack/amplify-pejuang-web-dev-150722/2d2af800-f0e1-11ea-b56b-0aac3d2f8c20",
            "AmplifyAppId": "d19x0ieoc0qyw2"
        },
        "categories": {
            "auth": {
                "cognito44a6f57d": {}
            },
            "function": {
                "PejuangWebApi": {}
            }
        }
    },
    "prod": {
        "awscloudformation": {
            "AuthRoleName": "amplify-pejuang-web-prod-113038-authRole",
            "UnauthRoleArn": "arn:aws:iam::101896602497:role/amplify-pejuang-web-prod-113038-unauthRole",
            "AuthRoleArn": "arn:aws:iam::101896602497:role/amplify-pejuang-web-prod-113038-authRole",
            "Region": "ap-southeast-1",
            "DeploymentBucketName": "amplify-pejuang-web-prod-113038-deployment",
            "UnauthRoleName": "amplify-pejuang-web-prod-113038-unauthRole",
            "StackName": "amplify-pejuang-web-prod-113038",
            "StackId": "arn:aws:cloudformation:ap-southeast-1:101896602497:stack/amplify-pejuang-web-prod-113038/8bcacc60-f3e7-11ea-ba01-0a41501dff4c",
            "AmplifyAppId": "d19x0ieoc0qyw2"
        },
        "categories": {
            "auth": {
                "cognito44a6f57d": {}
            },
            "function": {
                "PejuangWebApi": {}
            }
        }
    }
};


export default awsmobile;
