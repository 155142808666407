import React, { useState } from "react";
import { API } from "aws-amplify";
import { Storage } from "@aws-amplify/storage";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Upload,
  Layout,
  Modal,
  Row,
  Typography,
  DatePicker,
} from "antd";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";
import awsExports from "../../aws-exports";
import { getUidFromCookie } from "../../helpers/getUserId";

const { Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;
const moment = require("moment");

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const BannersCreate: React.FC = () => {
  const [loadingData, setLoadingData] = useState(false);

  const onFinish = (values: any) => {
    setLoadingData(true);
    onUpload(values.imageUrl[0]).then((res: any) => {
      const filename = encodeURI(res.key);
      const descValue = values.description.replace(/\n/g, ",");
      const description = descValue.split(",");
      const data = {
        title: values.title,
        imageUrl: `https://${awsExports.aws_user_files_s3_bucket}.s3-${awsExports.aws_user_files_s3_bucket_region}.amazonaws.com/public/${filename}`,
        subtitle: values.subtitle,
        periodStart: moment(values.periodStart).startOf("day").valueOf(),
        periodEnd: values.periodEnd
          ? moment(values.periodEnd).endOf("day").valueOf()
          : null,
        description,
        period: values.period,
      };

      postBanner(data)
        .then((response) => {
          Modal.success({
            content: "Success",
            onOk: (args: any) => {
              window.location.reload();
            },
          });
          setLoadingData(false);
        })
        .catch((err) => {
          alert("Error : " + err);
        })
        .catch((err) => console.log(err));
    });
  };

  const postBanner = async (data: any) => {
    return await API.post("pejuangweb", "/api/banner/", {
      body: data,
      headers: {
        "X-UID": getUidFromCookie(),
      },
    });
  };

  const onUpload = async (file: any) => {
    try {
      const response = await Storage.put(
        `banner/${file.originFileObj.name}`,
        file.originFileObj,
        {
          metadata: ["key: banner", `date: ${file.originFileObj.lastModified}`],
          level: "public",
        }
      );

      return response;
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  function onFinishFailed(errorInfo: any) {
    console.error("Failed:", errorInfo);
  }

  const normFile = (e: any) => {
    const state = { status: "upload" };
    let fileList = [...e.fileList];

    fileList = fileList.map((file) => {
      if (file.response) {
        file.status = state;
      }
      return file;
    });
    return e && fileList;
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content className="pejuang-main-content">
        <Row>
          <Col span={24}>
            <Card bordered={false} className="header-background">
              <Title level={4}>Add Banner</Title>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="content-layout left-align">
            <Card>
              {loadingData ? (
                <div>
                  {" "}
                  <LoadingOutlined style={{ fontSize: 24 }} spin />{" "}
                </div>
              ) : (
                <Form
                  name="basic"
                  {...layout}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: "Please input title!" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Subtitle (Optional)" name="subtitle">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      { required: true, message: "Please input description!" },
                    ]}
                  >
                    <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
                  </Form.Item>
                  <Form.Item
                    label="Period"
                    name="period"
                    rules={[{ required: true, message: "Please input title!" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Start Date"
                    name="periodStart"
                    rules={[
                      { required: true, message: "Please input Period!" },
                    ]}
                  >
                    <DatePicker />
                  </Form.Item>
                  <Form.Item label="End Date (Optional)" name="periodEnd">
                    <DatePicker />
                  </Form.Item>
                  <Form.Item
                    label="Image Banner"
                    name="imageUrl"
                    rules={[
                      { required: true, message: "Please upload Image!" },
                    ]}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    extra="Please upload image type JPG or PNG"
                  >
                    <Upload
                      name="image"
                      listType="picture"
                      multiple={false}
                      accept=".png, .jpg, .jpeg"
                      maxCount={1}
                    >
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                    <Button type="primary" htmlType="submit">
                      Save Banner
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default BannersCreate;
