import React, {useEffect, useState} from "react";
import {Col, Input, Row, Modal, Button, Upload, DatePicker} from "antd";
import { API, Storage } from "aws-amplify";
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import awsExports from "../../aws-exports";
import {getUidFromCookie} from "../../helpers/getUserId";

const { TextArea } = Input;
const moment = require('moment');

type Props = {
	isVisible : any,
	id : any,
	onSaveComplete : any
}

export const BannersModal: React.FC<Props> = (props: any) => {
  const [loadingData, setLoadingData] = useState(true);
	const [isVisible, setIsVisible] = useState(props.isVisible);
	const [bannerData, setBannerData] = useState<any | null>(null)
	const [imageForm, imageFormChange] = useState('')
	const [titleForm, titleFormChange] = useState('')
	const [periodForm, periodFormChange] = useState('')
	const [subtitleForm, subtitleFormChange] = useState('')
	const [descriptionForm, descriptionFormChange] = useState('')
	const [startDateForm, startDateFormChange] = useState(moment())
	const [endDateForm, endDateFormChange] = useState(moment())
	const [imgList, setImgList] = useState<Array<any>>([])


	useEffect(() => {
		getBannerDetail(props.id);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (bannerData !== null && props.id) {
			setLoadingData(false)
			imageFormChange(bannerData.imageUrl)
			titleFormChange(bannerData.title)
			periodFormChange(bannerData.period)
			subtitleFormChange(bannerData.subtitle)
			const desc = bannerData.description
			const text = desc.toString();
			const replaceText = text.replace(/,/g, '\n');
			descriptionFormChange(replaceText)
			startDateFormChange(moment(bannerData.periodStart))
			endDateFormChange(moment(bannerData.periodEnd))
			const images = {
				uid: '-1',
				name: "banner-image",
				status: 'done',
				url: bannerData.imageUrl
			}
			setImgList([images])
		}
	}, [bannerData, props.id])

  function onModalCancelButton() {
		setIsVisible(false);
	}
	function getBannerDetail(value: any) {
		setLoadingData(true)
		detailBannerEndpoint(value).then(res => {
			const payload = JSON.parse(res.responseBody.Payload);
      if (payload.success) {
				const body = JSON.parse(payload.body);
				setBannerData(body);
			}
		}).catch((err: any) => {
			setLoadingData(false)
			alert("Error : " + err)
		})
  }

	async function detailBannerEndpoint(params : any) {
		let init = {
			headers: {
				'X-UID': getUidFromCookie()
			}
		};
		return await API.get('pejuangweb', '/api/banner/' + params, init);
	}

	function changeImage(value: any) {
		let fileList = [...value.fileList];

		fileList = fileList.map(file => {
      if (file.response) {
				file.url = file.response.url;
				file.status = "upload"
      }
      return file;
		});

		setImgList(fileList);
		imageFormChange("")

    fileList = fileList.slice(-2);
	}


	const onUpload = async (file: any) => {
    try {
      const response = await Storage.put(`banner/${file.originFileObj.name}`, file.originFileObj, {
        metadata: ["key: banner", `date: ${file.originFileObj.lastModified}`],
        level: "public",
      });

      return response
    } catch (error) {
      console.error("Error uploading file => ", error);
    }
	}

	async function updateBannerEndpoint(params : any) {
		let init = {
			body: params,
			headers: {
				'X-UID': getUidFromCookie()
			}
		};
		return await API.put('pejuangweb', '/api/banner/' + props.id, init);
	}

	function onModalSaveButton() {
		if (imageForm.length < 1) {
			onUpload(imgList[0]).then((res: any) => {
				const filename = encodeURI(res.key)
				const image = `https://${awsExports.aws_user_files_s3_bucket}.s3-${awsExports.aws_user_files_s3_bucket_region}.amazonaws.com/public/${filename}`
				editBanner(image)
			}).catch(err => {
				console.error("error edit => ", err)
			})
		} else {
			editBanner(imageForm)
		}
	}

	function editBanner(values: any) {
		const descValue = descriptionForm.replace(/\n/g, ",")
        const description = descValue.split(",")
        const data = {
          title: titleForm,
          imageUrl: values,
          subtitle: subtitleForm,
          periodStart: moment(startDateForm).startOf('day').valueOf(),
          periodEnd: endDateForm ? moment(endDateForm).endOf('day').valueOf() : null,
					description,
					period: periodForm,
        }
		updateBannerEndpoint(data).then(res => {
			var payload = JSON.parse(res.responseBody.Payload);
			if (payload.success) {
				setIsVisible(false);
				props.onSaveComplete();
			} else {
				//show error
			}
		})
	}

  function renderContent() {
		if (loadingData) {
			return <div>
				<LoadingOutlined style={{ fontSize: 24 }} spin />
			</div>
		}
		return (
			<div>
				<Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
					<Col span={12} className="gutter-row">
						Image Banner:
					</Col>
					<Col span={12} className="gutter-row">
						Title:
					</Col>
				</Row>
				<Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
					<Col span={12} className="gutter-row">
            <Upload name="logo" listType="picture" maxCount={1} onChange={changeImage} fileList={imgList}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
					</Col>
					<Col span={12} className="gutter-row">
						<Input value={titleForm} onChange={(event => titleFormChange(event.target.value))}/>
					</Col>
        </Row>
        <br />
        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
					<Col span={12} className="gutter-row">
						Subtitle:
					</Col>
					<Col span={12} className="gutter-row">
						Description:
					</Col>
				</Row>
				<Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
					<Col span={12} className="gutter-row">
            <Input value={subtitleForm}  onChange={(event => subtitleFormChange(event.target.value))}/>
					</Col>
					<Col span={12} className="gutter-row">
            <TextArea autoSize={{ minRows: 2, maxRows: 6 }} value={descriptionForm} onChange={(event => descriptionFormChange(event.target.value))} />
					</Col>
        </Row>
        <br />
				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col span={12} className="gutter-row">
						Period:
					</Col>
					<Col span={8} className="gutter-row">
						Start Date:
					</Col>
					<Col span={8} className="gutter-row">
						End Date:
					</Col>
				</Row>
				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col span={8} className="gutter-row">
            <Input value={periodForm}  onChange={(event => periodFormChange(event.target.value))}/>
					</Col>
					<Col span={8} className="gutter-row">
            <DatePicker value={startDateForm} onChange={(dates, dateString) => startDateFormChange(dates)} />
					</Col>
					<Col span={8} className="gutter-row">
            <DatePicker value={endDateForm} onChange={(dates, dateString) => endDateFormChange(dates)} />
					</Col>
				</Row>
			</div>
		)
	}

	return (
		<Modal
			width="10"
			style={{marginLeft: '40px', marginRight: '40px'}}
			title="Banner Detail"
			visible={isVisible}
			onOk={() => onModalSaveButton()}
			okText="Save"
			onCancel={() => onModalCancelButton()}
		>
			{renderContent()}
		</Modal>
	)

}
